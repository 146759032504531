const Dock = {
  globals: window.dockGlobals,
};

Dock.getIconComponent = (icon, classes) => `
  <span class="c-icon c-icon--${icon}${classes ? ` ${classes}` : ''}">
    <svg class="c-icon__svg" width="24" height="24" aria-hidden="true">
      <use xlink:href="${Dock.globals.assetSpriteUrl}#${icon}"></use>
    </svg>
  </span>
`;

Dock.getAlertComponent = (icon, title, message, type = '', classes = '') => `
  <div class="c-alert${type ? ` c-alert--${type}` : ''}${classes ? ` ${classes}` : ''}">
    ${icon ? Dock.getIconComponent(icon, 'c-alert__icon') : ''}
    <div class="c-alert__content">
      <p class="c-alert__title">${title}</p>
      <div class="c-alert__desc s-richtext">${message}</div>
    </div>
  </div>
`;

export default Dock;
