import A11yDialog from 'a11y-dialog';
import { getJSONResponse } from '../utils/getReponse';

const $filtersDialog = document.querySelector('.js-filters-dialog');

if ($filtersDialog) {
  const dialog = new A11yDialog($filtersDialog);
  const $rubrics = $filtersDialog.querySelectorAll('.js-filters-rubric');
  const $form = $filtersDialog.querySelector('.js-filters-form');
  const $inputs = $filtersDialog.querySelectorAll('input[type="checkbox"]');
  const $submit = $filtersDialog.querySelector('.js-filters-submit');
  const $count = $submit.querySelector('.js-filters-count');
  const initialState = {
    checkedOnes: [],
    uncheckedOnes: [],
  };

  // Save inital state (reset to it when closing dialog)
  $inputs.forEach(($input) => {
    if ($input.checked) {
      initialState.checkedOnes.push($input);
    } else {
      initialState.uncheckedOnes.push($input);
    }
  });

  // Handle selection update
  const updateSelection = () => {
    $rubrics.forEach(($rubric) => {
      let checkedCount = 0;

      [...$inputs]
        .filter(($input) => $rubric.contains($input))
        .forEach(($input) => {
          if ($input.checked) {
            checkedCount++;
          }
        });

      $rubric.querySelector('.js-filters-rubric-title').setAttribute('data-selected', checkedCount);
    });

    // Fetch
    fetch($form.getAttribute('action'), {
      method: 'POST',
      headers: {
        // eslint-disable-next-line quote-props
        'Accept': 'application/json',
      },
      body: new FormData($form),
    })
      .then(getJSONResponse)
      .then((json) => {
        $count.textContent = json.data.total;
        $submit.href = json.data.url;
      })
      .catch((e) => console.error(e));
  };

  // Open dialog when clicking on filter (bar) items
  const $togglers = document.querySelectorAll('.js-filters-toggler');

  $togglers.forEach(($toggler) => {
    $toggler.addEventListener('click', () => {
      dialog.show();

      if ($toggler.hasAttribute('data-filter-key')) {
        const $rubric = [...$rubrics].find(($element) => $element.getAttribute('data-filter-key') === $toggler.getAttribute('data-filter-key'));

        if ($rubric) {
          $rubric.open = true;
          $rubric.scrollIntoView();
        }
      }
    });
  });

  dialog.on('show', () => {
    dialog.$el.querySelector('[role="document"]').addEventListener('animationend', () => {
      dialog.$el.classList.remove('is-opening');
    }, {
      once: true,
    });

    dialog.$el.classList.add('is-opening');
  });

  // Reset to initial state when closing dialog
  dialog.on('hide', () => {
    dialog.$el.setAttribute('aria-hidden', 'false');

    dialog.$el.querySelector('[role="document"]').addEventListener('animationend', () => {
      dialog.$el.setAttribute('aria-hidden', 'true');

      $inputs.forEach(($input) => {
        if (initialState.checkedOnes.indexOf($input) !== -1) {
          $input.checked = true;
        }

        if (initialState.uncheckedOnes.indexOf($input) !== -1) {
          $input.checked = false;
        }
      });

      updateSelection();

      $rubrics.forEach(($rubric) => {
        $rubric.removeAttribute('open');
      });

      dialog.$el.classList.remove('is-closing');
    }, {
      once: true,
    });

    dialog.$el.classList.add('is-closing');
  });

  // Listen input change
  $inputs.forEach(($element) => {
    $element.addEventListener('change', updateSelection);
  });

  // Handle clear
  const $clear = $filtersDialog.querySelector('.js-filters-clear');

  $clear.addEventListener('click', () => {
    $inputs.forEach(($input) => {
      $input.checked = false;
    });
    updateSelection();
  });
}
