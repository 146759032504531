import { throttle } from 'throttle-debounce';
import Toggler from '../utils/Toggler';
import getCSSCustomProp from '../utils/getCSSCustomProp';

export default class Header {
  constructor(root) {
    const _ = this;

    // Elements
    _.$root = root;
    _.$burger = _.$root.querySelectorAll('.js-header-burger');
    _.$navigation = _.$root.querySelector('.js-header-nav');
    _.$navItems = _.$root.querySelectorAll('.js-header-nav-item');
    _.$submenuRoots = _.$root.querySelectorAll('.js-header-submenu-root');
    _.$subrubrics = _.$root.querySelectorAll('.js-header-subrubric');

    // States
    _.mode = 'small';
    _.height = _.$root.offsetHeight;
    _.lastScrollTop = window.pageYOffset;
    _.isHidden = false;

    // Throttled functions
    _.throttledWatchScroll = throttle(250, _.watchScroll.bind(_));
    _.throttledWatchResize = throttle(250, _.watchResize.bind(_));

    // Bound functions
    _.handleSubmenuOutsideClick = _.handleSubmenuOutsideClick.bind(_);

    // Togglers syndication
    _.togglers = {
      nav: null,
      submenus: null,
      subrubrics: null,
      search: null,
    };

    // Nav toggler
    _.togglers.nav = new Toggler([[_.$navigation, _.$burger]], {
      escClose: true,
      callbacks: {
        beforeClose: () => {
          _.$navigation.classList.add('is-closing');
          _.$navigation.addEventListener('animationend', () => {
            _.$navigation.classList.remove('is-closing');
          });
        },
        beforeOpen: () => {
          // Prevent search and nav to be open at the same time
          if (_.togglers.search.currentId) {
            _.togglers.search.close(_.togglers.search.currentId);
          }

          _.$navigation.classList.add('is-opening');
          _.$navigation.addEventListener('animationend', () => {
            _.$navigation.classList.remove('is-opening');
          });
        },
        afterOpen: () => {
          document.body.classList.add('nav-is-open');
        },
        afterClose: () => {
          document.body.classList.remove('nav-is-open');

          // Ensure submenus or subrubrics are also closed
          if (_.togglers.submenus.currentId) {
            _.togglers.submenus.close(_.togglers.submenus.currentId);
          }

          if (_.togglers.subrubrics.currentId) {
            _.togglers.subrubrics.close(_.togglers.subrubrics.currentId);
          }
        },
      },
    });

    // Submenus toggler
    const submenuTogglerItems = [];

    for (let i = 0; i < _.$submenuRoots.length; i++) {
      submenuTogglerItems.push([
        _.$submenuRoots[i].querySelector('.js-header-submenu-menu'),
        _.$submenuRoots[i].querySelectorAll('.js-header-submenu-parent'),
      ]);
    }

    _.togglers.submenus = new Toggler(submenuTogglerItems, {
      oneAtATime: true,
      callbacks: {
        beforeOpen: ($content, $togglers) => {
          // Hide other menu items when opening a submenu on mobile
          if (_.mode === 'small') {
            for (let i = 0; i < _.$navItems.length; i++) {
              if (!_.$navItems[i].contains($togglers[0])) {
                _.$navItems[i].classList.add('is-hidden');
              }
            }
          }
        },
        afterOpen: () => {
          document.body.classList.add('submenu-is-open');
        },
        afterClose: () => {
          // Reset visibility of other menu items when opening a submenu on mobile
          if (_.mode === 'small') {
            for (let i = 0; i < _.$navItems.length; i++) {
              _.$navItems[i].classList.remove('is-hidden');
            }
          }

          document.body.classList.remove('submenu-is-open');
        },
      },
    });

    // Subrubrics toggler
    const subrubricTogglerItems = [];
    for (let i = 0; i < _.$subrubrics.length; i++) {
      subrubricTogglerItems.push([
        _.$subrubrics[i].querySelector('.js-header-subrubric-content'),
        _.$subrubrics[i].querySelectorAll('.js-header-subrubric-toggler'),
      ]);
    }

    _.togglers.subrubrics = new Toggler(subrubricTogglerItems, {});

    // Search toggler
    const $searchTogglers = _.$root.querySelectorAll('.js-header-search-toggler');
    const updateSearchStatedIcons = (state) => {
      [...$searchTogglers].forEach(($element) => {
        const $icon = $element.querySelector('.js-stated-icon');

        if ($icon) {
          $icon.setAttribute('data-state', state);
        }
      });
    };
    _.togglers.search = new Toggler([[_.$root.querySelector('.js-header-search'), $searchTogglers]], {
      escClose: true,
      callbacks: {
        beforeOpen: () => {
          // Prevent search and nav to be open at the same time
          if (_.togglers.nav.currentId) {
            _.togglers.nav.close(_.togglers.nav.currentId);
          }
        },
        afterOpen: () => {
          document.body.classList.add('search-is-open');
          _.$root.querySelector('.js-header-search').addEventListener('transitionend', () => {
            _.$root.querySelector('.js-header-search-input').focus();
          }, {
            once: true,
          });

          updateSearchStatedIcons('open');
        },
        afterClose: () => {
          document.body.classList.remove('search-is-open');

          updateSearchStatedIcons('close');
        },
      },
    });
  }

  mount() {
    const _ = this;

    _.togglers.nav.attach();
    _.togglers.submenus.attach();
    _.togglers.subrubrics.attach();
    _.togglers.search.attach();

    _.watchResize();
    _.watchScroll();

    window.addEventListener('resize', _.throttledWatchResize);
    window.addEventListener('scroll', _.throttledWatchScroll);
    document.addEventListener('click', _.handleSubmenuOutsideClick);
  }

  unmount() {
    const _ = this;

    _.togglers.nav.detach();
    _.togglers.submenus.detach();
    _.togglers.subrubrics.detach();
    _.togglers.search.detach();

    window.removeEventListener('scroll', _.throttledWatchScroll);
    window.removeEventListener('resize', _.throttledWatchResize);
    document.remove('click', _.handleSubmenuOutsideClick);
  }

  watchResize() {
    const _ = this;

    _.height = _.$root.offsetHeight;

    // Handle navigation differences between small and large resolutions
    if (window.matchMedia(`(max-width: ${getCSSCustomProp('--header-large-bp', undefined, 'int') - 0.001}em)`).matches) {
      if (_.mode !== 'small') {
        document.body.classList.remove('submenu-is-open');
        document.body.classList.remove('nav-is-open');

        if (_.togglers.submenus.currentId) {
          _.togglers.submenus.close(_.togglers.submenus.currentId);
        }

        _.mode = 'small';
      }

      if (_.togglers.nav.status === 'disabled') {
        _.togglers.nav.attach();
      }
    } else {
      if (_.mode !== 'large') {
        document.body.classList.remove('submenu-is-open');
        document.body.classList.remove('nav-is-open');

        if (_.togglers.submenus.currentId) {
          _.togglers.submenus.close(_.togglers.submenus.currentId);
        }

        _.mode = 'large';
      }

      if (_.togglers.nav.status === 'enabled') {
        _.togglers.nav.detach();
      }
    }
  }

  watchScroll() {
    const _ = this;
    const newScrollTop = window.pageYOffset;
    // const staticOrFloatingPoint = _.height;
    const staticOrFloatingPoint = 24;

    if (newScrollTop < _.lastScrollTop && _.isHidden) {
      _.slideDown();
    }

    if (newScrollTop > staticOrFloatingPoint) {
      _.$root.classList.add('is-floating');
    } else {
      _.$root.classList.remove('is-floating');
    }

    if (
      newScrollTop > staticOrFloatingPoint
      && newScrollTop > _.lastScrollTop
      && !_.togglers.nav.currentId
      && !_.togglers.submenus.currentId
      && !_.isHidden
    ) {
      _.slideUp();
    }

    _.lastScrollTop = newScrollTop;
  }

  slideDown() {
    const _ = this;

    _.$root.classList.remove('slide-up');
    _.$root.classList.add('slide-down');

    document.body.classList.add('header-is-fixed');
    document.body.classList.add('header-is-displayed');
    document.body.classList.remove('header-is-hidden');

    _.isHidden = false;
  }

  slideUp() {
    const _ = this;

    _.$root.classList.remove('slide-down');
    _.$root.classList.add('slide-up');

    document.body.classList.add('header-is-hidden');
    document.body.classList.remove('header-is-displayed');

    _.isHidden = true;
  }

  // Close submenus when clicking outside of header on large resolutions.
  handleSubmenuOutsideClick(e) {
    const _ = this;
    const toggler = _.togglers.submenus;

    if (_.mode === 'large' && toggler.currentId && !_.$root.contains(e.target)) {
      toggler.close(toggler.currentId);
    }
  }
}
