import { throttle } from 'throttle-debounce';
import Toggler from '../utils/Toggler';
import getCSSCustomProp from '../utils/getCSSCustomProp';

export default class Header {
  constructor(root) {
    const _ = this;

    // Elements
    _.$root = root;
    _.$rubrics = _.$root.querySelectorAll('.js-footer-rubric');

    // States
    _.mode = 'small';

    // Throttled functions
    _.throttledWatchResize = throttle(250, _.watchResize.bind(_));

    // Togglers syndication
    _.togglers = {
      rubrics: null,
    };

    // Subrubrics toggler
    const rubricTogglersItems = [];
    for (let i = 0; i < _.$rubrics.length; i++) {
      rubricTogglersItems.push([
        _.$rubrics[i].querySelector('.js-footer-rubric-content'),
        _.$rubrics[i].querySelectorAll('.js-footer-rubric-toggler'),
      ]);
    }

    _.togglers.rubrics = new Toggler(rubricTogglersItems, {});
  }

  mount() {
    const _ = this;

    _.togglers.rubrics.attach();

    _.watchResize();

    window.addEventListener('resize', _.throttledWatchResize);
  }

  unmount() {
    const _ = this;

    _.togglers.rubrics.detach();

    window.removeEventListener('resize', _.throttledWatchResize);
  }

  watchResize() {
    const _ = this;

    _.height = _.$root.offsetHeight;

    // Handle differences between small and large resolutions
    if (window.matchMedia(`(max-width: ${getCSSCustomProp('--footer-large-bp', undefined, 'int') - 0.001}em)`).matches) {
      if (_.mode !== 'small') {
        _.mode = 'small';
      }

      if (_.togglers.rubrics.status === 'disabled') {
        _.togglers.rubrics.attach();
      }
    } else {
      if (_.mode !== 'large') {
        _.mode = 'large';
      }

      if (_.togglers.rubrics.status === 'enabled') {
        _.togglers.rubrics.detach();
      }
    }
  }
}
