import Swiper, { A11y, Autoplay, Navigation, Pagination } from 'swiper';

Swiper.use([A11y, Autoplay, Navigation, Pagination]);

const $elements = document.querySelectorAll('.js-slideshow');

if ($elements.length) {
  $elements.forEach(($element) => {
    const slider = new Swiper($element.querySelector('.swiper'), {
      a11y: {
        firstSlideMessage: 'Ceci est la première slide',
        lastSlideMessage: 'Ceci est la dernière slide',
        paginationBulletMessage: 'Aller à la slide {{index}}',
        prevSlideMessage: 'Slide précédente',
        nextSlideMessage: 'Slide suivante',
      },
      navigation: {
        nextEl: $element.querySelector('.swiper-button-next'),
        prevEl: $element.querySelector('.swiper-button-prev'),
      },
      pagination: {
        el: $element.querySelector('.swiper-pagination'),
        type: 'bullets',
        clickable: true,
      },
      loop: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
    });
  });
}
