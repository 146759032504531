import Swiper, { A11y, Navigation } from 'swiper';

Swiper.use([A11y, Navigation]);

const $elements = document.querySelectorAll('.js-gallery');

if ($elements.length) {
  $elements.forEach(($element) => {
    const $thumbs = $element.querySelectorAll('.js-gallery-thumb');

    const slider = new Swiper($element.querySelector('.swiper'), {
      a11y: {
        firstSlideMessage: 'Ceci est la première slide',
        lastSlideMessage: 'Ceci est la dernière slide',
        paginationBulletMessage: 'Aller à la slide {{index}}',
        prevSlideMessage: 'Slide précédente',
        nextSlideMessage: 'Slide suivante',
      },
      navigation: {
        nextEl: $element.querySelector('.swiper-button-next'),
        prevEl: $element.querySelector('.swiper-button-prev'),
      },
      on: {
        init: (swiper) => {
          $thumbs[swiper.realIndex].classList.add('is-active');
        },
        slideChange: (swiper) => {
          const $iframe = swiper.slides[swiper.previousIndex].querySelector('iframe');

          // Pause videos
          if ($iframe) {
            switch ($iframe.getAttribute('data-service')) {
              case 'youtube':
                $iframe.contentWindow.postMessage(JSON.stringify({
                  event: 'command',
                  func: 'pauseVideo',
                  args: '',
                }), '*');
                break;

              case 'vimeo':
                $iframe.contentWindow.postMessage(JSON.stringify({
                  method: 'pause',
                }), '*');
                break;

              default: {
                // Reset iframe (bad move as it reload it)
                const iframeSrc = $iframe.src;
                $iframe.src = iframeSrc;
                break;
              }
            }
          }
        },
      },
    });

    // Thumbs behavior
    if ($thumbs.length) {
      $thumbs.forEach(($thumb, i) => {
        $thumb.addEventListener('click', () => {
          slider.slideTo(i);
        });
      });

      slider.on('slideChange', (swiper) => {
        $thumbs.forEach(($thumb, i) => {
          if (i === swiper.realIndex) {
            $thumb.classList.add('is-active');
          } else {
            $thumb.classList.remove('is-active');
          }
        });
      });
    }
  });
}
