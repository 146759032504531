import { getHTMLResponse } from '../utils/getReponse';

const rootSelector = '.js-ll-pagination';
const $paginations = document.querySelectorAll(rootSelector);

if ($paginations.length) {
  const countSelector = '.js-ll-pagination-count';
  const nextSelector = '.js-ll-pagination-next';

  // Load next page
  const loadNext = ($pagination) => {
    const targetSelector = $pagination.getAttribute('data-target');
    const $count = $pagination.querySelector(countSelector);
    const $next = $pagination.querySelector(nextSelector);
    const $target = document.querySelector(targetSelector);

    fetch($next.href)
      .then(getHTMLResponse)
      .then(($html) => {
        $target.append(...$html.querySelector(targetSelector).childNodes);

        const $remotePagination = $html.querySelector(`${rootSelector}[data-target="${targetSelector}"]`);
        const $remoteCount = $remotePagination.querySelector(countSelector);
        const $remoteNext = $remotePagination.querySelector(nextSelector);

        if ($count && $remoteCount) {
          $count.innerHTML = $remoteCount.innerHTML;
        }

        if ($remoteNext) {
          $next.href = $remoteNext.href;
        } else {
          $next.remove();
        }
      })
      .catch((e) => {
        console.error(e);
        window.location = $next.href;
      });
  };

  // Handle click on next buttons
  const clickHandler = (e) => {
    const $element = e.target.closest(nextSelector);
    if (!$element) return;

    e.preventDefault();

    loadNext($element.closest(rootSelector));
  };

  // Wire logic to DOM
  $paginations.forEach(($pagination) => {
    const $next = $pagination.querySelector(nextSelector);

    if ($next) {
      $next.addEventListener('click', clickHandler);
    }
  });
}
