import { getJSONResponse } from '../utils/getReponse';
import LWDock from '../LWDock';

const $finders = document.querySelectorAll('.js-finder');

if ($finders.length) {
  // Output result
  const renderResult = ($finder, data = []) => {
    const $resultContainer = $finder.querySelector('.js-finder-result-container');
    let htmlString;

    if (data.reference) {
      const values = [data.reference];

      htmlString = `
        <dl class="c-finder__result c-finder__result--assoc">
          <dt class="c-finder__result-label">${$finder.getAttribute('data-finder-result-label')}</dt>
          ${values.map((value) => `<dd class="c-finder__result-value">${value}</dd>`).join('')}
        </dl>
      `;
    } else if (data.manual) {
      htmlString = `
        <a class="c-button c-button--big c-button--secondary c-button--dark c-finder__result c-finder-result--button" href="${data.manual.url}" target="_blank">
          <span class="c-button__inner">
            ${LWDock.getIconComponent('arrow-right', 'c-button__icon')}
            <span class="c-button__text">${$finder.getAttribute('data-finder-result-label')} ${data.manual.title}</span>
          </span>
        </a>
      `;
    } else {
      htmlString = `<p class="c-finder__result c-finder__result--phrase">${$finder.getAttribute('data-finder-result-none')}</p>`;
    }

    $resultContainer.innerHTML = htmlString;
  };

  // Fetch on change
  document.addEventListener('change', (ect) => {
    const $finder = ect.target.closest('.js-finder');
    if (!$finder) return;

    fetch($finder.getAttribute('action'), {
      method: 'POST',
      headers: {
        // eslint-disable-next-line quote-props
        'Accept': 'application/json',
      },
      body: new FormData($finder),
    })
      .then(getJSONResponse)
      .then((json) => renderResult($finder, json.data))
      .catch((e) => console.error(e));
  });
}
