import { throttle } from 'throttle-debounce';
import extend from '../utils/extend';
import getCSSCustomProp from '../utils/getCSSCustomProp';

export default class Summary {
  constructor($root, options) {
    this.options = extend({
      trackSelector: '.js-summary-track',
      itemSelector: '.js-summary-item',
    }, options);

    this.$root = $root;
    this.$track = $root.querySelector(this.options.trackSelector);
    this.$items = $root.querySelectorAll(this.options.itemSelector);
    this.$trackedElements = [...this.$items].map(($item) => document.querySelector($item.getAttribute('data-track')));

    this.mode = 'horizontal';
    this.areaToSpy = {
      top: 0,
      bottom: 0,
    };
    this.currentIndex = null;
    this.observer = null;

    this.throttledWatchResize = throttle(250, this.watchResize.bind(this));
  }

  mount() {
    this.checkMode();
    this.updateArea();
    this.checkScrollPosition();

    if (!this.observer) {
      this.observer = new IntersectionObserver(this.observerCallback.bind(this), {
        rootMargin: '-50% 0px -50% 0px',
      });
    }

    this.$trackedElements.forEach(($element) => {
      this.observer.observe($element);
    });

    window.addEventListener('resize', this.throttledWatchResize);
  }

  unmount() {
    this.observer.disconnect();

    window.removeEventListener('resize', this.throttledWatchResize);
  }

  updateArea() {
    this.areaToSpy = {
      top: this.$trackedElements[0].offsetTop,
      bottom: this.$trackedElements[this.$trackedElements.length - 1].offsetTop,
    };
  }

  checkScrollPosition() {
    if (window.scrollY < this.areaToSpy.top) {
      this.updateActiveElement(0);
    }

    if (window.scrollY > this.areaToSpy.bottom) {
      this.updateActiveElement(this.$items.length - 1);
    }
  }

  updateActiveElement(index) {
    [...this.$items].forEach(($item, i) => {
      if (i === index) {
        $item.classList.add('is-active');
      } else {
        $item.classList.remove('is-active');
      }
    });

    this.currentIndex = index;
    this.renderTrackIndic();
  }

  observerCallback(entries) {
    let oneIsIntersecting = false;

    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.updateActiveElement(this.$trackedElements.indexOf(entry.target));
        oneIsIntersecting = true;
      }
    });

    if (!oneIsIntersecting) {
      this.checkScrollPosition();
    }
  }

  renderTrackIndic() {
    const size = this.mode === 'horizontal' ? this.$items[this.currentIndex].offsetWidth : this.$items[this.currentIndex].offsetHeight;
    const offset = this.mode === 'horizontal' ? this.$items[this.currentIndex].offsetLeft - this.$track.offsetLeft : this.$items[this.currentIndex].offsetTop - this.$track.offsetTop;

    this.$root.style.setProperty('--summary-track-indic-size', `${size}px`);
    this.$root.style.setProperty('--summary-track-indic-offset', `${offset}px`);
  }

  checkMode() {
    if (window.matchMedia(`(max-width: ${getCSSCustomProp('--summary-vertical-bp', this.$root, 'int') - 0.001}em)`).matches) {
      if (this.mode !== 'horizontal') {
        this.mode = 'horizontal';
      }
    } else if (this.mode !== 'vertical') {
      this.mode = 'vertical';
    }
  }

  watchResize() {
    this.$root.classList.add('no-transition');

    this.checkMode();
    this.renderTrackIndic();

    setTimeout(() => {
      this.$root.classList.remove('no-transition');
    }, 250);
  }
}
