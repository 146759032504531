import Swiper, { A11y, Navigation, Scrollbar } from 'swiper';

Swiper.use([A11y, Navigation, Scrollbar]);

const $elements = document.querySelectorAll('.js-catalog-push');

if ($elements.length) {
  $elements.forEach(($element) => {
    const slider = new Swiper($element.querySelector('.swiper'), {
      a11y: {
        firstSlideMessage: 'Ceci est la première slide',
        lastSlideMessage: 'Ceci est la dernière slide',
        paginationBulletMessage: 'Aller à la slide {{index}}',
        prevSlideMessage: 'Slide précédente',
        nextSlideMessage: 'Slide suivante',
      },
      navigation: {
        nextEl: $element.querySelector('.swiper-button-next'),
        prevEl: $element.querySelector('.swiper-button-prev'),
      },
      slidesPerGroup: 1,
      slidesPerView: 'auto',
      spaceBetween: 3,
      scrollbar: {
        el: $element.querySelector('.swiper-scrollbar'),
        draggable: true,
        hide: false,
      },
      breakpoints: {
        370: {
          slidesPerGroup: 2,
        },
        535: {
          slidesPerGroup: 3,
        },
        695: {
          slidesPerGroup: 4,
        },
        885: {
          slidesPerGroup: 5,
        },
        960: {
          slidesPerGroup: 1,
          scrollbar: false,
        },
        1115: {
          slidesPerGroup: 2,
          scrollbar: false,
        },
        1820: {
          slidesPerGroup: 3,
          scrollbar: false,
        },
        2235: {
          slidesPerGroup: 4,
          scrollbar: false,
        },
        2615: {
          slidesPerGroup: 5,
          scrollbar: false,
        },
      },
    });
  });
}
