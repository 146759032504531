export function getResponse(response, type) {
  if (response.ok) {
    let expectedCT = '';
    let processor = (r) => r;

    switch (type) {
      case 'html':
        expectedCT = 'text/html';
        processor = async (r) => {
          const htmlText = await r.text();
          const parser = new DOMParser();
          return parser.parseFromString(htmlText, 'text/html');
        };
        break;

      case 'json':
        expectedCT = 'application/json';
        processor = (r) => r.json();
        break;

      default:
        break;
    }

    if (expectedCT) {
      const contentType = response.headers.get('content-type');

      if (contentType && contentType.indexOf(expectedCT) !== -1) {
        return processor(response);
      }

      return Promise.reject(new Error(`Received "${contentType}" content-type when expecting "${expectedCT}"`));
    }

    return response;
  }

  return Promise.reject(new Error(`The request was not successful (${response.status}: ${response.statusText})`));
}

export function getHTMLResponse(response) {
  return getResponse(response, 'html');
}

export function getJSONResponse(response) {
  return getResponse(response, 'json');
}
