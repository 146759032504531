import { getJSONResponse } from '../utils/getReponse';
import LWDock from '../LWDock';
import getFragmentFromString from '../utils/getFragmentFromString';

const $forms = document.querySelectorAll('.js-form');

if ($forms.length) {
  const feedbackSelectorClass = 'js-form-feedback';
  const feedbackClasses = `c-form__feedback ${feedbackSelectorClass}`;

  $forms.forEach(($form) => {
    const $submit = $form.querySelector('[type="submit"]');
    let isAlreadyProcessing = false;

    // Add global feedback
    const appendFeedback = (fragment) => {
      const $currentFeedback = $form.querySelector(`.${feedbackSelectorClass}`);

      if ($currentFeedback) {
        $currentFeedback.replaceWith(fragment);
      } else {
        $form.prepend(fragment);
      }

      $form.scrollIntoView();
    };

    const arrayAreIdenticals = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);

    const fields = {};

    // Return array of input values for one field
    const getFieldValues = (fieldKey) => {
      if (fieldKey in fields) {
        const values = [];

        fields[fieldKey].$inputs.forEach(($input) => {
          if (($input.type === 'radio' || $input.type === 'checkbox')) {
            values.push($input.checked);
          } else {
            values.push($input.value);
          }
        });

        return values;
      }

      return false;
    };

    // Handle each fields
    $form.querySelectorAll('[data-field]').forEach(($field) => {
      const fieldKey = $field.getAttribute('data-field');
      const data = {
        $field,
        $inputs: $field.querySelectorAll('input, select, textarea'),
        values: [],
        initalValues: [],
        error: !!$field.querySelector('.c-form-note--error'),
      };

      fields[fieldKey] = data;

      const fieldValues = getFieldValues(fieldKey);
      fields[fieldKey].values = fieldValues;
      fields[fieldKey].initalValues = fieldValues;

      // Remove error state if value has change
      data.$inputs.forEach(($input) => {
        $input.addEventListener('change', () => {
          const field = fields[fieldKey];
          const newValues = getFieldValues(fieldKey);

          if (field.error && !arrayAreIdenticals(field.values, newValues)) {
            field.$field.querySelector('.c-form-note--error')?.remove();

            field.$field.querySelectorAll('.c-form-input, .c-form-option').forEach(($component) => {
              $component.classList.remove('c-form-input--error', 'c-form-option--error');
            });

            field.error = false;
          }

          field.values = newValues;
        });
      });
    });

    // Submit form
    $form.addEventListener('submit', (evt) => {
      evt.preventDefault();

      if (isAlreadyProcessing) return;

      isAlreadyProcessing = true;
      $submit.classList.add('is-loading');

      const headers = {
        // eslint-disable-next-line quote-props
        'Accept': 'application/json',
      };

      // TODO : handle file submission
      // if ($form.getAttribute('enctype')) {
      //   headers['Content-type'] = $form.getAttribute('enctype');
      // }

      // Remove all errors before fetching a new response
      Object.keys(fields).forEach((key) => {
        if (fields[key].error) {
          fields[key].$field.querySelector('.c-form-note--error').remove();
          fields[key].$field.querySelectorAll('.c-form-input, .c-form-option').forEach(($component) => {
            $component.classList.remove('c-form-input--error', 'c-form-option--error');
          });
          fields[key].error = false;
        }
      });

      $form.querySelector(`.${feedbackSelectorClass}`)?.remove();

      fetch($form.getAttribute('action'), {
        method: 'POST',
        headers,
        body: new FormData($form),
      })
        .then(getJSONResponse)
        .then((json) => {
          // Success
          if (json.data) {
            appendFeedback(
              getFragmentFromString(
                LWDock.getAlertComponent(
                  'success',
                  'Le formulaire a été envoyé avec succès',
                  `<p>${json.data.message ? json.data.message : 'Nous reviendrons vers vous dans les plus brefs délais.'}</p>`,
                  'success',
                  feedbackClasses,
                ),
              ),
            );

            // Reset all fields to the initial value
            // Potential misbehavior : if form contains errors from the start
            Object.keys(fields).forEach((key) => {
              fields[key].$inputs.forEach(($input, index) => {
                if (($input.type === 'radio' || $input.type === 'checkbox')) {
                  $input.checked = fields[key].initalValues[index];
                } else {
                  $input.value = fields[key].initalValues[index];
                }
              });
            });

          // Error
          } else if (json.errors) {
            const { errors } = json;
            // Extract global error
            const globalError = errors.find((error, index) => {
              if (error.name === $form.getAttribute('name')) {
                errors.splice(index, 1);
                return true;
              }

              return false;
            });

            // Append errors to each fields
            errors.forEach((error) => {
              const $field = $form.querySelector(`[data-field="${error.name}"]`);

              if ($field) {
                const $fieldControl = $field.querySelector('.c-form-field__control');

                const fragment = getFragmentFromString(`
                  <div class="c-form-note c-form-note--error c-form-field__feedback">
                      ${LWDock.getIconComponent('error', 'c-form-note__icon')}
                      <p class="c-form-note__text">${error.message}</p>
                  </div>
                `);

                if (fields[error.name].error) {
                  const $previousError = $field.querySelector('.c-form-note--error');

                  $previousError.replaceWith(fragment);
                } else {
                  $field.insertBefore(fragment, $fieldControl);
                }

                fields[error.name].$inputs.forEach(($input) => {
                  const $component = $input.closest('.c-form-input, .c-form-option');

                  if ($component.classList.contains('c-form-input')) {
                    $component.classList.add('c-form-input--error');
                  } else if ($component.classList.contains('c-form-option')) {
                    $component.classList.add('c-form-option--error');
                  }
                });

                fields[error.name].error = true;
              }
            });

            // Append global feedback
            appendFeedback(
              getFragmentFromString(
                LWDock.getAlertComponent(
                  'error',
                  'Votre saisie comporte des erreurs',
                  `<p>${globalError?.message ? globalError.message : 'Veuillez ré-essayer plus tard. Désolé pour la gène occasionée.'}</p>`,
                  'error',
                  feedbackClasses,
                ),
              ),
            );
          }
        })
        // If the request went wrong
        .catch((e) => {
          // console.error(e);

          appendFeedback(
            getFragmentFromString(
              LWDock.getAlertComponent(
                'error',
                'Une erreur est survenue',
                '<p>Veuillez ré-essayer plus tard. Désolé pour la gène occasionée.</p>',
                'error',
                feedbackClasses,
              ),
            ),
          );
        })
        // In all cases
        .finally(() => {
          isAlreadyProcessing = false;
          $submit.classList.remove('is-loading');
        });
    });
  });
}
