import Popin from '../components/Popin';

const $elements = document.querySelectorAll('.js-popin');

if ($elements.length) {
  // Mount all popins
  const popins = [...$elements].map(($element) => {
    const popin = new Popin($element);
    popin.mount();
    return popin;
  });

  // Find ONE (and only one) popin to show
  const onePopinOnly = popins.find((popin) => !popin.hasCookie() && popin.matchDevice());

  // Showtime !
  if (onePopinOnly) {
    onePopinOnly.show();
  }
}
