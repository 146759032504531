import Swiper, { A11y, Navigation, Scrollbar } from 'swiper';

Swiper.use([A11y, Navigation, Scrollbar]);

const $elements = document.querySelectorAll('.js-rslider');

if ($elements.length) {
  $elements.forEach(($element) => {
    const slider = new Swiper($element.querySelector('.swiper'), {
      a11y: {
        firstSlideMessage: 'Ceci est la première slide',
        lastSlideMessage: 'Ceci est la dernière slide',
        paginationBulletMessage: 'Aller à la slide {{index}}',
        prevSlideMessage: 'Slide précédente',
        nextSlideMessage: 'Slide suivante',
      },
      navigation: {
        nextEl: $element.querySelector('.swiper-button-next'),
        prevEl: $element.querySelector('.swiper-button-prev'),
      },
      slidesPerGroup: 2,
      slidesPerView: 2,
      spaceBetween: 12,
      scrollbar: {
        el: $element.querySelector('.swiper-scrollbar'),
        draggable: true,
        hide: false,
      },
      breakpoints: {
        720: {
          slidesPerGroup: 3,
          slidesPerView: 3,
          scrollbar: false,
        },
        960: {
          slidesPerGroup: 3,
          slidesPerView: 3,
          spaceBetween: 24,
          scrollbar: false,
        },
      },
    });
  });
}
