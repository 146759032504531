const $form = document.querySelector('.js-contactform');

if ($form) {
  const $serviceRow = $form.querySelector('.js-contactform-service');
  const $serviceInputs = $serviceRow.querySelectorAll('input');
  let serviceValue = null;
  const $universeRow = $form.querySelector('.js-contactform-universe');
  const $universeInput = $universeRow.querySelector('select');
  let universeValue = null;
  const $serialRow = $form.querySelector('.js-contactform-serial');
  const $serialInput = $serialRow.querySelector('input');

  const updateValues = () => {
    const checkedServiceArr = [...$serviceInputs].filter(($input) => $input.checked);
    serviceValue = checkedServiceArr.length ? checkedServiceArr[0].value : null;

    universeValue = $universeInput.value;

    if (serviceValue === 'Sav' && universeValue === 'Balneo/Spa') {
      $serialRow.classList.remove('u-hidden');
      $serialInput.required = true;
    } else {
      $serialRow.classList.add('u-hidden');
      $serialInput.required = false;
    }
  };

  updateValues();

  [...$serviceInputs, $universeInput].forEach(($input) => {
    $input.addEventListener('change', updateValues);
  });
}
