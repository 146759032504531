const $elements = document.querySelectorAll('.js-stated-icon');

if ($elements.length) {
  const updateIcon = ($icon) => {
    const $svgUse = $icon.querySelector('use');
    const baseUrl = $svgUse.getAttribute('xlink:href').split('#')[0];
    const currentState = $icon.getAttribute('data-state');

    $svgUse.setAttribute('xlink:href', `${baseUrl}#${$icon.getAttribute(`data-state-${currentState}`)}`);
  };

  const config = { attributes: true, childList: true };
  const callback = (mutationsList) => {
    [...mutationsList].forEach((mutation) => {
      if (mutation.type === 'attributes' && mutation.attributeName === 'data-state') {
        updateIcon(mutation.target);
      }
    });
  };
  const observer = new MutationObserver(callback);

  [...$elements].forEach(($element) => {
    updateIcon($element);
    observer.observe($element, config);
  });
}
