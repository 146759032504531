import Summary from '../components/Summary';

const $elements = document.querySelectorAll('.js-summary');

if ($elements.length) {
  [...$elements].forEach(($element) => {
    const summary = new Summary($element);
    summary.mount();
  });
}
