import A11yDialog from 'a11y-dialog';
import mobile from 'is-mobile';
import { setCookie, getCookie } from '../utils/cookies';

export default class Popin {
  constructor($element) {
    this.$element = $element;
    this.cookieName = null;
    this.delay = null;
    this.device = null;
    this.days = null;
    this.dialog = null;
    this.isMobile = mobile();
  }

  mount() {
    this.cookieName = this.$element.id;

    if ('popinDelay' in this.$element.dataset) {
      this.delay = parseInt(this.$element.dataset.popinDelay, 10) * 1000;
    }

    if ('popinDevice' in this.$element.dataset) {
      this.device = this.$element.dataset.popinDevice;
    }

    if ('popinDays' in this.$element.dataset) {
      this.days = parseInt(this.$element.dataset.popinDays, 10);
    }
  }

  // unmount() {}

  matchDevice() {
    return this.device === 'all'
      || (this.device === 'mobile' && this.isMobile)
      || (this.device === 'desktop' && !this.isMobile);
  }

  hasCookie() {
    return this.cookieName && getCookie(this.cookieName);
  }

  setCookie() {
    if (this.cookieName) {
      setCookie(this.cookieName, 'true', this.days);
    }
  }

  show() {
    if (!this.dialog) {
      this.initDialog();
    }

    if (this.delay) {
      setTimeout(() => {
        this.dialog.show();
        this.delay = false;
      }, this.delay);
    } else {
      this.dialog.show();
    }
  }

  hide() {
    this.dialog.hide();
  }

  initDialog() {
    this.dialog = new A11yDialog(this.$element);

    this.dialog.on('show', () => {
      this.setCookie();

      this.dialog.$el.querySelector('[role="document"]').addEventListener('animationend', () => {
        this.dialog.$el.classList.remove('is-opening');
      }, {
        once: true,
      });

      this.dialog.$el.classList.add('is-opening');
    });

    this.dialog.on('hide', () => {
      this.dialog.$el.setAttribute('aria-hidden', 'false');
      this.dialog.$el.querySelector('[role="document"]').addEventListener('animationend', () => {
        this.dialog.$el.setAttribute('aria-hidden', 'true');
        this.dialog.$el.classList.remove('is-closing');
      }, {
        once: true,
      });

      this.dialog.$el.classList.add('is-closing');
    });
  }
}
