// INTERSECTION OBSERVER / REVEAL LOGIC
let uid = 0;
let rows = {};
const references = {};
let observables = [];
let observer;

// Functions
function findReference($referer) {
  let reference = false;

  [...Object.keys(references)].forEach((key) => {
    if (Object.hasOwnProperty.call(references, key) && references[key].$referer === $referer) {
      reference = references[key];
    }
  });

  return reference;
}

function findReferenceUid($referer) {
  const reference = findReference($referer);

  if (reference) {
    return reference.uid;
  }

  return false;
}

function isAnObservable($target) {
  return observables.find(($element) => $element === $target);
}

function removeObservable($target) {
  observables.splice(observables.indexOf($target), 1);
  observer.unobserve($target);
}

function revealElement($target) {
  $target.classList.add('is-revealed');

  const event = new CustomEvent('animate:start');
  $target.dispatchEvent(event);

  if (isAnObservable($target)) {
    removeObservable($target);
  }
}

function revealByReference(reference) {
  if (reference) {
    for (let i = 0; i < reference.$elements.length; i++) {
      const delay = reference.$elements[i].dataset.animateDelay ? reference.$elements[i].dataset.animateDelay : 0;

      if (reference.$elements[i].dataset.animateStagger === 'true') {
        setTimeout(revealElement, i * (delay || 250), reference.$elements[i]);
      } else if (delay) {
        setTimeout(revealElement, delay, reference.$elements[i]);
      } else {
        revealElement(reference.$elements[i]);
      }
    }

    if (isAnObservable(reference.$referer)) {
      removeObservable(reference.$referer);
    }
  }
}

function init($elements) {
  const newObservables = [];

  $elements.forEach(($element) => {
    if ($element.dataset.animateByParent) {
      const $referer = $element.closest($element.dataset.animateByParent);

      if ($referer) {
        const referenceUid = findReferenceUid($referer);

        if (!referenceUid) {
          uid++;
          references[uid] = { uid, $referer, $elements: [] };
          newObservables.push($referer);
          $referer.dataset.animateReferenceUid = uid;
        }

        references[uid].$elements.push($element);
      }
    } else if ($element.dataset.animateStagger === 'true') {
      const top = $element.getBoundingClientRect().top + window.pageYOffset;

      if (typeof rows[top] === 'undefined') {
        rows[top] = { $referer: $element, $elements: [] };
      }

      rows[top].$elements.push($element);
    } else {
      newObservables.push($element);
    }
  });

  // Classic Stagger Referers
  [...Object.keys(rows)].forEach((top) => {
    if (Object.hasOwnProperty.call(rows, top)) {
      const row = rows[top];

      uid++;
      references[uid] = { uid, $referer: row.$referer, $elements: row.$elements };
      newObservables.push(row.$referer);
      row.$referer.dataset.animateReferenceUid = uid;
    }
  });

  rows = {};

  newObservables.forEach((observable) => {
    observer.observe(observable);
  });

  observables = observables.concat(newObservables);
}

// Setup Observer
const observerConfig = { rootMargin: '0px 0px -20% 0px', threshold: 0 };

function observerCallback(entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      if (entry.target.dataset.animateReferenceUid) {
        revealByReference(references[entry.target.dataset.animateReferenceUid]);
      } else {
        revealElement(entry.target);
      }
    }
  });
}

observer = new IntersectionObserver(observerCallback, observerConfig);

// Init
init(document.querySelectorAll('.js-animate'));
